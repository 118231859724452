.pca-introduction {
  display: flex;
  height: 56.9rem;
  max-width: 970px;
  width: 209px;
  color: #003e7a;
  font-family: "Open Sans";
  font-size: 36px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 44px;
  text-align: left;
}

.pca-descriptive-text {
  width: auto;
  height: 130px;
  color: #888888;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 22px;
  margin-top: -16px;
  margin-left: 2px;
  text-align: justify;
}

.pca-descriptive-text a {
  text-decoration: none !important;
}

.pca-introduction .pca-main {
  text-align: start;
  padding: 3rem 6rem;
  min-height: 38rem;
  font-family: "Open Sans";
  margin-top: 38px;
  font-size: 36px;
  margin-left: 13px;
}

.pca-subtitle {
  width: 709px;
  height: 27px;
  color: #003e7a;
  font-family: "Open Sans";
  font-size: 16px !important;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 1.5px;
  line-height: 30px;
  text-align: left;
  margin-top: 11px;
  margin-left: 2px;
}

.mt-auto-btn {
  margin-top: 334px;
}

.btn-next {
  margin-top: 25px;
  margin-right: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pca-introduction-container-btn-signup {
  float: right;
  margin-right: 0;
  width: 205px;
  height: auto !important;
}

.pca-introduction-container-btn-align{
  float: right;
  margin-right: 0;
  margin-top: 7px;
}

.pca-introduction-container-btn-back-align-top {
  margin-top: 0px;   
  padding-left: 0px !important;
}

@media (max-width: 1150px) and (min-width: 1024px) {
  .pca-introduction .pca-main {
    padding: 3rem 0rem;
  }
}

.pca-intro-style{
  font-size: 20px;
}