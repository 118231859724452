.btn-hra-primary {
  background-color: #5B9EF3;
  color: #FFFF;
  font-weight: bold;
  width: 195px;
  height: 48px;
  border-radius: inherit;
}
.btn-hra-link {
  background-color: inherit;
  color: #FFFF;
  text-transform: uppercase;
  font-weight: bold;
  width: 195px;
  height: 48px;
  border-radius: inherit;

}