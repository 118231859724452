.top-bar {
  margin: 0;
  overflow: hidden;
  background-color: #003e7a;
  height: 71px;
}
.create {
  text-align: left;
  opacity: 0.7;
  color: #ffffff;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}

.create ul {
  list-style-type: none;
  padding: 6px 27px 0 27px;
  margin: 0;
}
.create ul li {
  display: inline-block;
  padding: 1px 22px 0 0;
}

.title-name p {
  color: #ffffff;
  padding-left: 15px;
  font-size: 24px;
  margin: 0;
  font-size: 26px;
  font-family: "Open Sans";
  font-weight: 500;
  letter-spacing: 0.03px;
}

.name-align {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.cards ul li {
  display: inline-block;
  padding: 0 32px 10px 10px;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1.31px;
  font-weight: bold;
}

.cards ul li:last-child {
  padding-right: 18px;
}
.header-link, .header-link:hover, .header-link:active, .header-link:focus {
  text-decoration: none;
  color: inherit;
}

cards > a#btn-programs, a#btn-programs.disabled, a#btn-programs:disabled {
  background-color: none !important;
  color: #ffffff;
  border-color: none;
}