.reset.container {
    background: white;
    width: 100%;
    height: 831px;
  }
  
  .bg-cl-reset-password {
    background: #fff;
    height: 831px;
  }
  
  .banner-div-reset-password {
    height: 831px;
    overflow: hidden;
    margin-top: 44px;
  }
  .maintitle {
    width: 350px;
    height: 45px;
    color: #003e7a;
    font-family: "Open Sans";
    font-size: 36px;
    font-style: normal;
    letter-spacing: normal;
    line-height: 44px;
    text-align: left;
    margin-left: 2%;
    margin-top: 6%;
  }
 
  .password-padding{
    margin-top: 30px;
  }
  .success-msg {
    color:  #2bd384;
   
    margin-top: -16px;
  }