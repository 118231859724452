.App {
  /* text-align: center; */
  max-width: 1440px;
  margin: 0 auto;
  background: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.navbar {
  border-bottom: 1px solid darkgrey;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* 
.btn-hra-primary {
  background-color: #5B9EF3;
  color: #FFFF;
  font-weight: bold;
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.app-mob-view-dis {
  align-items: center;
}

.app-para-design {
  color: #888888;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 22px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.app-head-design {
  color: #003e7a;
  font-family: "Open Sans";
  font-size: 36px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 44px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

@media (max-width: 1024px) and (min-width: 370px) {
  .app-mob-view-dis {
    min-height: 100vh;
    /* width: 88%; */
    vertical-align: middle;
  }

  .collapsed {
    display: none;
  }

  .d-inline-block.align-top {
    margin-top: 0px !important;
  }
}