.dialogClassName {
  max-width: 1110px;
}
/* 
.dialogSignUp {
  padding-bottom: 70px;
} */
.contentClassName {
  height: 470px !important;
  border-radius: inherit;
}
.modalBody {
  margin-top: 50px;
}
.modal-header{
  border-bottom: none;
}