.tb-head {
    background-color: #003e7a;
    height: 40px;
    color: #ffffff;
    font-family: "Open Sans";
    font-weight: bold;
    font-size: 12px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    vertical-align: middle !important;
    text-align: initial;
}

.tb-head-tenant1 {
    background-color: #003e7a;
    height: 40px;
    color: #ffffff;
    font-family: "Open Sans";
    font-weight: bold;
    font-size: 12px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    vertical-align: middle !important;
    text-align: initial;
}
.tb-head-tenant2 {
    background-color: rgb(55,47,43);
    height: 40px;
    color: #ffffff;
    font-family: "Open Sans";
    font-weight: bold;
    font-size: 12px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    vertical-align: middle !important;
    text-align: initial;
}
.table-responsive{
    min-height: 100vh;
}
.tbl-body-row {
    height: 66px;
    border-left: 1px black;
}

.tbl-body-row:nth-child(even) {
    background-color: #f8f8f8;
}

.tbl-body-row:hover {
    background-color: rgba(148, 191, 162, 0.2);
}