.sign-up-login-bt{
  padding-left: 9px;
  text-decoration: none;
  width: 101px;
  height: 40px;
  color: #aeb0b5;
  font-weight: bold;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.3px;
  line-height: 24px;
  text-align: left;
  text-transform: uppercase;
  margin: 1px;
  background-color: white;
}
.sign-up-loginbtn-mrg{
  margin-top: 65px;
}
.signup-container h1.maintitle {
  width: 350px;
  height: 45px;
  color: #003e7a;
  font-family: "Open Sans";
  font-size: 36px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 44px;
  text-align: left;
  margin-left: 2%;
  margin-top: 6%;
}
.signup-container h3.subtitle {
  display: flex;
  width: 300px;
  height: 22px;
  color: #888888;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 22px;
  text-align: left;
  margin-left: 2%;
}

.signup-container .col-7.form {
  margin-top: 2%;
}
.signup-container .row {
  margin-bottom: 30px !important;
}

.signup-container input.form-control {
  border-radius: 3px;
  border: 1px solid #979797;
  background-color: #f8f8f8;
}

.signup-container .select-input {
  border-radius: 3px;
  border: 1px solid #979797;
  background-color: #f8f8f8;
}

.signup-container .form-check-label.agree {
  width: 486px;
  height: 22px;
  color: #212121;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  margin-top: 4px;
  margin-left: 4px;
}

.signup-container .form-check-label.subscription {
  width: 486px;
  height: 22px;
  color: #212121;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  margin-top: 3%;
  margin-left: 4px;
}

.signup-container .form-check-input.agree {
  width: 22px;
  height: 22px;
}

.signup-container .form-check-input.subscription {
  width: 22px;
  height: 22px;
  margin-top: 3%;
}

.signup-container .btn.btn-light {
  width: 71px;
  height: 32px;
  color: #aeb0b5;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.3px;
  line-height: 24px;
  text-align: left;
  text-transform: uppercase;
  margin-top: 23%;
  margin-left: 6%;
  padding: 0px;
  background-color: white;
}

.signup-container button.signup-button.btn.btn-primary {
  width: 140px;
  height: 48px;
  margin-top: 58px;
  background-color: #5b9ef4;
  border-color: #5b9ef4;
  border-radius: 0px;
  margin-top: 19%;
}

.signup-container .form-select {
  background-color: #f8f8f8;
  border: 1px solid #979797;
}
.ssign {
  width: 140px;
  height: 48px;
  margin-top: 58px;
  background-color: #5b9ef4;
  border-color: #5b9ef4;
  border-radius: 0px;
  margin-top: 19%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .ssign:hover {
  color: black;

} */
.err-msg{
  color: #dc3545;
/* margin-left: 415px; */
margin-top: -16px;
}

.form-check-label{
  padding-left: 7px;
  margin-top: 3px;
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #198754;
  color: #198754;
  box-shadow: 9999px 0 0 -5px #198754;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: .25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #198754;
  color: #198754;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #198754;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #198754;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: .5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #198754;
  }

  30% {
    box-shadow: 9984px 0 0 2px #198754;
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #198754;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #198754;
  }

  30% {
    box-shadow: 9999px 0 0 2px #198754;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #198754;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #198754;
  }

  30% {
    box-shadow: 10014px 0 0 2px #198754;
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #198754;
  }
}
.dot-pulse{
  float: right;
    margin-right: 150px;
    margin-top: 9px;
}
.suc-label{
  color: #198754;
}
.stage{
  margin-top: 25px;
}
