.newprojects-form-input-H {
  height: 48px;
}

.newprojects-para-ali-cen {
  padding-top: 16px;
  text-align: center;
}

/* .add-btn:hover {
  color: black;

} */

.newprojects-keywords-projectcatagory {
  background-color: #f0efef;
  padding-bottom: 1px;
}

.newprojects-keywords-projectcatagory-vH-cen-ali {
  padding-top: 25px;
}

.newprojects-projectlocation-implementingentity-mH-ali {
  margin-top: 30px;
}

.newprojects-word-or-bwt-wid {
  margin-right: 12px;
}

@media (max-width: 1199px) and (min-width: 620px) {
  .newprojects-para-ali-cen {
    padding-top: 0px;
  }
  .newprojects-keywords-projectcatagory-wid-txt-keyword,
  .newprojects-keywords-projectcatagory-wid-txt-projectcatagory {
    width: 90%;
  }
  .newprojects-keywords-projectcatagory-wid-txt-keyword {
    margin-bottom: -15px;
  }
}

@media (max-width: 768px) and (min-width: 620px) {
  .newprojects-keywords-projectcatagory-wid-txt-keyword,
  .newprojects-keywords-projectcatagory-wid-txt-projectcatagory {
    margin-left: 16px;
  }
}
.req-field {
  color: #888888;
  font-family: 'Open Sans';
  font-size: 14px !important;
  font-style: normal;
  letter-spacing: normal;
  line-height: 22px;
  text-align: left;
  padding-bottom: 5px;
}
.new-subtitle{
  color: #888888;
  font-family: 'Open Sans';
  font-size: 14px !important;
  font-style: normal;
  letter-spacing: normal;
  line-height: 22px;
  text-align: left;
  padding-bottom: 5px;
}

.title-content .title-body{
margin-bottom: 0px;
}
span.req-field a {
  text-decoration: none !important;
}

.error-text {
  margin-top: 5px;
}
.rbt-highlight-text {
  padding-right: 0px;
}

.project-field-row {
  margin-bottom: 30px !important;
}

.keyword-tooltip {
  width: 20px;
  float: right;
  right: 292px;
  bottom: 183px;
  margin-top: -28mm;
}
