.eligible-program-container-btn-eligible-pad {
  width: 127px;
  height: 25px;
  border-radius: 4px;
  background-color: #d6d7da;
}

.eligible-program-container-btn-style {
  width: 101px;
  height: 28px;
  color: #323a44;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: center;
  padding-top: 4px;
}

/* .eligible {
  margin-left: 164px;
} */

.row-hover:hover {
  background: red;
}

.row{
  margin-left: -14px;
}

.column{
  margin-right: -150px;
}

.layout-main.eligible {
  overflow: auto;
  min-height: 100vh;
  margin: auto;
}

.eligible-programs-heading {
  padding-top: 100px;
  margin-left: -11px;
}

.eligible-programs-btnsign {
  width: 208px;
  height: 48px;
}

.eligible-programs-subheading {
  padding-top: 92px;
}

.eligible-program-container-btn-style-1 {
  width: 101px;
  height: 28px;
  color: #323a44;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: center;
  padding-top: 4px;
  float: left;
  margin-left: 18px;
}

.eligible-program-container-tbl-design {
  width: 1110px;
  margin-top: 15px;
}

.eligibility-program-table-head-style-tenant1 {
  border-color: #003e7a;
  height: 40px;
  background-color: #003e7a;
}
.eligibility-program-table-head-style-tenant2 {
  border-color: rgb(55,47,43);
  height: 40px;
  background-color: rgb(55,47,43)  ;
}
table#all-programs-table tbody:nth-child(odd) {
  background-color: #f8f8f8;
}

table#eligible-programs-table tbody:nth-child(odd) {
  background-color: #f8f8f8;
}

table#all-programs-table tbody {
  border: 1px solid #f2f2f2;
}

.eligible-program-programname {
  width: 543px;
  height: 16px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}


/* .eligible-program-signup:hover {
  color: black;

} */

.eligible-program-typeofproject {
  width: 432px;
  height: 16px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}

.eligible-program-projectcategory {
  width: 578px;
  height: 16px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}

.eligible-program-amount {
  width: 146px;
  height: 16px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: right;
}


.eligible-program-fund {
  width: 146px;
  height: 16px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: right;
}

.eligible-program-eligibility {
  width: fit-content;
  height: 16px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  padding-left: 30px !important;
}

.eligible-container-btn-style {
  width: 99px;
  height: 20px;
  color: #323a44;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.eligible-program-tb-body {
  height: 78px;
  vertical-align: middle;
  border-color: white;
  transition: max-height 0.5s ease;
  border-style: hidden;

}
.expanded {
  max-height: 500px; /* Set a value larger than the expected maximum height */
}
.eligible-program-tb-body .ep-container-accordian td {
  transition: opacity 0.5s ease; /* Add a smooth transition for opacity */
}

.eligible-program-tb-body:hover {
  background-color: #e5e8f0;
}

.ep-name {
  /* width: 316px; */
  height: 19px;
  color: #212121;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}

.ep-body-pc {
  /* width: 110px; */
  height: 36px;
  color: #323a44;

  font-size: 12px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
}

.ep-body-top {
  /* width: 110px; */
  height: 37px;
  color: #323a44;
  font-size: 12px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
}

.ep-body-amt {
  width: 90px;
  height: 20px;
  color: #323a44;
  font-size: 12px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: right;
}

.ep-body-eligible {
  width: fit-content;
  display: flex;
  height: 100%;
  align-items: center;
  height: 78px;
  border-bottom-width: 0px !important;
  padding-left: 30px !important;
}

.eligible-program-disclaimer-drop-down {
  width: 256px;
  height: 58px;
  color: #323a44;
  font-size: 12px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
}
.div-hover:hover {
  background-color: #f8f8f8;
}
.recipients {
  padding: 13px 0px 0px 30px;
}

.eligibility-program-non-elgible-btn {
  width: 100px;
  height: 25px;
  border-radius: 4px;
  background-color: #aeb0b5;
  opacity: 0.5;
}

.eligibility-program-non-elgible-letter {
  width: 100px;
  color: #323a44 !important;
  font-weight: bold;
  font-size: 12px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
  padding-top: 2px;
}

.eligible-program-recipients {
  /* width: 112px; */
  height: 20px;
  color: #323a44;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
  margin-top: 15px;
}

.eligible-program-recipients-drop-down {
  color: #323a44;
  font-size: 12px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  width: 72%;
  text-align: left;
}

.eligible-program-disclaimer {
  width: 67px;
  height: 20px;
  color: #323a44;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
}

.two-layer-icon {
  width: 20px;
  /* margin-right: 5px; */
}

.eligible-program-purpose {
  width: 53px;
  height: 20px;
  color: #323a44;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
}

.eligible-program-fedralCost {
  width: 77px;
  height: 20px;
  color: #323a44;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
}

.eligible-program-fedralShare {
  
  color: #323a44;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
}
.eligible-program-stateShare{
  color: #323a44;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;

}
.eligible-program-stateShareBody{
  margin-top: 5px;
  margin-left: 121px;
  color: #323a44;
  font-size: 12px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;

}

.eligible-program-federalShareBody{
  margin-top: 5px;
  margin-left: -14px;
  color: #323a44;
  font-size: 12px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;

}

.eligible-program-documents {
  width: 214px;
  height: 20px;
  color: #323a44;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
  /* margin-top: -26px; */
  margin-left: 4px;


}
.eligible-program-links{
  width: 214px;
  height: 20px;
  color: #323a44;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
  margin-left: 4px;
  margin-top: 12px;

}
.eligible-program-similar {
  width: 136px;
  height: 20px;
  color: #323a44;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
}

.main-container{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.sub-container{
  padding-right: 20px;
}
.card-fund{
  height: 100px;
  width: 330px;
}
/* .card-fund {
  margin-left: -145px;
    width: 298px;
    height: 129px;
    margin-top: 36px;
    min-width: 237px;
    cursor: pointer;
    width: 226px;
    margin-bottom: 7px;
  margin-left: -147px;
  width: 298px;
  height: 105px;
  margin-top: 36px;
  cursor: pointer;
  margin-bottom: 7px;
} */

.card-fund:hover {
  background-color: white; 
  box-shadow: 0 1px 1px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.12);

}
.eligible-program-purpose-drop-body {
  color: #323a44;
  font-size: 12px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
}
.eligible-program-document-body{
  color: #323a44;
  font-size: 12px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  padding-right: 30px;
  text-align: left;
  margin-left: 12px;
}
.eligible-program-link-body{
  color: #323a44;
  font-size: 12px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  padding-right: 30px;
  text-align: left;
  margin-left: 4px;
}
.icon-align{
  margin-left: -12px;
  margin-right: 25px;
  
}
.icon-style{
  color: brown;
  size:inherit;
  margin-left:-11px;
}
.link-icon-style{
  size:inherit;
  margin-left:2px;
}
/* Define the badge style */
.badge {
  background-color: #babec1; /* Background color of the badge */
  color: black; /* Text color of the badge */
  padding: 0px 10px; /* Adjust padding to control the size of the badge */
  border-radius: 8px; /* Make it round */
  font-size: 12px;
  line-height: 25px;
  width: max-content;
}

.main p.badge-nofo-label {
  background-color: #babec1; 
  background: #D8D8D8;
  color: rgba(50, 58, 68, 0.80); /* Text color of the badge */
  padding: 0px 10px; /* Adjust padding to control the size of the badge */
  border-radius: 20px;
  font-size: 12px;
  font-weight: normal;
  line-height: 25px;
  margin-bottom: 5px;
  width: max-content;
}
.main p.badge-due {
  background-color: #93092a; /* Background color of the badge */
  color: black; /* Text color of the badge */
  padding: 0px 10px; /* Adjust padding to control the size of the badge */
  border-radius: 13px; /* Make it round */
  font-size: 12px;
  font-weight: normal;
  line-height: 25px;
  margin-bottom: 5px;
  width: max-content;
}

.main p.badge-nofo-label.status-due {
  background-color: rgb(69,106,99);
  color: #ffffff;
}

.main p.badge-nofo-label.status-expired {
  background-color: #babec1;
  color: rgba(50, 58, 68, 0.80);
}

.main p.badge-nofo-label.status-tba {
  background-color: #aacdfa;
  color: black;
}
/* Add this CSS to improve spacing and alignment */
.recipients {
  margin-bottom: 0rem;
  /* margin-top: 20px; Add margin to the top of the row */
}

.eligible-program-similar {
  margin-right: 10px; /* Add margin to the right of the heading */
}

.eligible-program-purpose-drop-body {
  margin: 0; /* Reset margin to remove default spacing */
  /* margin-left: 12px; */
  padding-right: 15px; /* Add padding to the clickable element */

}

.eligible-program-links-drop-body {
  margin: 0; /* Reset margin to remove default spacing */
  margin-left: 12px;

  padding-right: 20px;
}

/* Style for clicked card */
.eligible-program-purpose-drop-body.clicked {
  background-color: #f0f0f0; /* Change background color when clicked */
}

.eligible-program-drp-down,
.eligible-program-drp-up {
  margin-right: 30px;
  width: 27px;
  height: 27px;
  float: right;
}

#all-programs-table
  > tbody
  > tr.eligible-program-tb-body.collapsed
  > td
  > svg.eligible-program-drp-up,
#eligible-programs-table
  > tbody
  > tr.eligible-program-tb-body.collapsed
  > td
  > svg.eligible-program-drp-up {
  display: none;
}

#all-programs-table
  > tbody
  > tr.eligible-program-tb-body:not(.collapsed)
  > td
  > svg.eligible-program-drp-up,
#eligible-programs-table
  > tbody
  > tr.eligible-program-tb-body:not(.collapsed)
  > td
  > svg.eligible-program-drp-up {
  display: block;
}

#all-programs-table
  > tbody
  > tr.eligible-program-tb-body.collapsed
  > td
  > svg.eligible-program-drp-down,
#eligible-programs-table
  > tbody
  > tr.eligible-program-tb-body.collapsed
  > td
  > svg.eligible-program-drp-down {
  display: block;
}

#all-programs-table
  > tbody
  > tr.eligible-program-tb-body:not(.collapsed)
  > td
  > svg.eligible-program-drp-down,

#eligible-programs-table
  > tbody
  > tr.eligible-program-tb-body:not(.collapsed)
  > td
  > svg.eligible-program-drp-down {
  /* display: none; */
  transition: ease-in;
  ;
}


.arrow-icon{
    margin: 20px 10px;
    margin-left: 50px;
    padding: 0px !important;
    width: 100%;
}

.table > thead {
  vertical-align: unset;
}

.title-head {
  padding-left: 8px;
  margin-bottom: 4px;
}

.title-body-main {
  padding-left: 0px;
}

.title-body {
  padding-left:8px;
}
.eligible-programs-description{
  padding: 12px;
}
.popover {
  margin-left: -10px;
  box-shadow: 0px 0px 8px rgb(158, 153, 153);
  max-width: 368px;
  font-size: 12px;
  font-family: 'Open Sans';
  border-radius: 0rem !important;
}

.popover .popover-arrow::after {
  margin-left: 7px;
}
.sort-data {
  height: 17px;
  width: 17px;
  cursor: Pointer;
}
.sort-data:hover {
  transform: scale(1.2);
}

#all-programs-table > thead > tr > th.eligible-program-typeofproject,
#all-programs-table > thead > tr > td.ep-body-pc {
  width: 205px;
}
#all-programs-table > thead > tr > th.eligible-program-projectcategory,
#all-programs-table > thead > tr > th.ep-body-top {
  width: 192px;
}

#all-programs-table > thead > tr > th.eligible-program-programname,
#all-programs-table > thead > tr > td.ep-name {
  width: 420px;
}

.eligibility-container-btn-learn {
  float: left;
  margin-top: 5px;
  padding-top: 11px;
}

.eligibility-container-btn-learn:hover {
  color: black;
}
.eligibility-container-accordian {
  height: 376px !important;
}

.eligible-description {
  color: #888888;
  font-family: 'Open Sans';
  font-size: 14px !important;
  font-style: normal;
  letter-spacing: normal;
  line-height: 22px;
  text-align: left;
}

.eligible-content{
  padding: 0px;
}
.expanded {
  max-width: 500px;
  background-color: #e0e0e0;
}

.accordion {
  display: flex;
  flex-direction: column;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.accordion-content {
  margin: 5px 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.expanded .accordion {
  max-height: 100px; /* Adjust the height to your liking */
}

.expanded .accordion-content {
  opacity: 1;
}
.card1 {
  width: 200px;
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
}
.share-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px; 
}
.column {
  float: left;
  width: 25%;
  /* padding: 0px; */
  height: 35px; /* Should be removed. Only for demonstration */
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
.eligible-program-cost-share{
  display: flex;
}
.eligible-program-cost-share-text{
  padding-right: 15px;
}
.text-style{
  font-size: 12px;
  margin-left: -12px;

}

.rotate-icon {
  transform: rotate(180deg); 
}

.data-container {
  transform: scaleY(1); 
  transform-origin: top;
  transition: transform 0.5s ease-in-out;
}

.fade-in {
  transform: scaleY(1); /* Set scale to 1 for normal height */
}

.fade-out {
  transform: scaleY(0); /* Set scale to 0 for hiding */
}

.eligible-transition {
  position: absolute;

}
.clock-icon-size{
  width: 1rem;
  height: 0.8rem;
  margin-top: -2px;
}
.close-icon-size{
  width: 1rem;
  height: 0.8rem;
  margin-top: -2px;
}
.link-icon-size{
  width: 1rem;
  height: 1rem;
}
.document-icon-size{
  width: 1rem;
  height: 1rem;
}
.programstatus-dropdown{
  width: 24%;
  margin-left: 785px;
 }

.placeholder-icon {
  margin-right: 5px; 
  vertical-align: middle;
}
.position-relative {
  position: relative;
}
.filter-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 61%; 
  z-index: 1; 
}
.programstatus-dropdown {
  padding-left: 30px  !important; 
}

.csv-link {
  text-decoration: none;  
  align-items: center;
  text-align: center;
  display: flex;
}