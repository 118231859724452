body {
  margin: 0;
  font-family: "Open Sans", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif, -apple-system, BlinkMacSystemFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* max-width: 1440px;
  margin: 0 auto !important;  */
  /* float: none !important;  */
  /* background-color: navy; */
  background: #9ca4ab !important;
}

h2,
h3 {
  color: #003e7a;
  font-family: "Open Sans", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main p {
  opacity: 1;
  font-size: 13px;
}
.main h2 {
  margin-bottom: 0px;
}
.form-select {
  padding: 12px !important;
  /* margin-bottom: 30px !important; */
}
.form-control {
  padding: 12px !important;
  /* margin-bottom: 30px !important; */
}
.btn-hra-primary {
  float: right;
}
.mb-3.email{
  margin-top: 5%;
}
