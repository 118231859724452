.text-align{
    text-align: center;
background-color: #f8f9fa;
display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    border-top: 1px solid #e9ecef;
    font-family: "Open Sans";
font-size: 14px;
color: #888888;
width: 1440px;
}
.text-align a{
    text-decoration: none;
}