.contact.row {
    width: 1110px;
    height: 523px;
}
.contatus-error-message-dis{
    margin-top: -25px;
    margin-bottom: -21px;
}
.contact-text{
 margin-left: 19%;
 margin-top: 1%;
}

p.title-body.contact-subtitle {
    width: 351px;
    height: 64px;
    color: #888888;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    letter-spacing: normal;
    line-height: 22px;
    text-align: left;
}

input.contact-email.form-control 
    {
    width: 540px;
    height: 48px;
    border-radius: 3px;
    border: 1px solid #979797;
    background-color: #f8f8f8;
}

input.contact-sub.form-control 
    {
    width: 540px;
    height: 48px;
    border-radius: 3px;
    border: 1px solid #979797;
    background-color: #f8f8f8;
    margin-top: 6%;
}

textarea.contact-message.form-control {

    width: 540px;
    height: 116px;
    border-radius: 3px;
    border: 1px solid #979797;
    background-color: #f8f8f8;
    margin-top: 6%;
    padding-bottom: 80px !important;
}

/* .contact-button:hover {
    color: black;
} */

button.contact-button.btn.btn-hra-primary {
    width: 113px;
    height: 48px;
    background-color: #5b9ef4;
    margin-top: 6%;
    margin-right: 14%;
}

.con-recaptcha{
    margin-top: 6%;
}
