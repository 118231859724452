.feture-fund-card{
    margin-left: 42px;
    width: 1209px;
}

.fund-card-height{
  height: 200px;
}
.fund-title-head {
    padding-left: 43px;
    margin-bottom: 4px;
  }
  .feat-fund-learnmore {
    float: left;
    margin-top: 5px;
    padding-top: 11px;
    cursor: pointer;
  }

  
.feature-fund-heading {
  padding-top: 86px;
  margin-left: -6px;
}

.feat-fund-program{
  font-size: 26px;
  color: #173f40;
}
.funding-container-align{
  margin-bottom: 40px;
}