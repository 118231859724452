.table-space {

    margin-top: -414px;
}
/* 
.table>:not(caption)>*>* {
    padding: 0rem 0rem;
} */

.table-flex {
    display: flex;
}

.score-card-tbl-head {
    width: 161.61px;
    padding-top: 20px;
    padding-left: 30px;
    vertical-align: middle;
    padding: 10px;
}

.score-card-thead-tenant1 {
    width: 50%;
    background-color: #003e7a;
    height: 38px;
    color: #ffffff;
    font-family: "Open Sans";
    font-weight: bold;
    font-size: 12px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    vertical-align: middle !important;
    text-align: initial;

}


.score-card-thead-tenant2 {
    width: 50%;
    background-color:rgb(55,47,43);
    height: 38px;
    color: #ffffff;
    font-family: "Open Sans";
    font-weight: bold;
    font-size: 12px;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    vertical-align: middle !important;
    text-align: initial;
    margin-left: 1px;

}

.score-card-tot {
    width: 12.25rem;
    padding: 10px;
    padding-left: 30px !important;
    margin-bottom: 16px;

}

.th-border {
    border-right: 2px solid;
    padding-right: 52px;
    width: 73px;
    padding-left: 9px;
}

.td-border {
    border-right: 2px solid #f8f8f8;

}


.score-card-th-pc {
   
    width: 310px;
}

.score-card-tbody {
    text-align: left;
    width: 250px;
}

.score-table .pcName {
    text-align: left;
    border-bottom: 1px solid #dee2e6;
}

.score-card-title-projects {
    width: 498px;
    height: 45px;
    color: #003e7a;
    font-family: "Open Sans";
    font-size: 36px;
    font-style: normal;
    letter-spacing: normal;
    line-height: 44px;
    text-align: left;
}

.score-card-tot {
    float: right;
    margin-right: -24px;
    margin-top: -5px;
    color: #5b616a;
    font-family: "Open Sans";
    font-size: 24px;
}

.criteria-score-info {
    float: right;
    
}

.score-card-score {
    float: right;
    color: #aeb0b5;
    padding: 3px;
    margin-right: 6px;
    font-size: 12px;
    width: 30px;
    margin-top: 0px;
    line-height: 18px;
}


table.pcName.space-align td {
    padding: 10px;

}

.pcName{
    padding-top: 8px !important;
}

table.space-align.criteria-items td {
    padding: 5px;

}

.criteria-items>* {
    color: #aeb0b5;
   
   
}


.score-card-border {
    border: none;
}


.badge,
.badge-component,
.badge-criteria {
    width: 51px;

    color: black;
    border-radius: 3px;
    vertical-align: middle;
    display: inline-block;
    font-family: 'Open Sans';
}


.badge {
    line-height: 30px;
    width: 77px;
    border-radius: 4px;
}


.badge-criteria {
    line-height: 23px;
    width: 54px;
    border-radius: 4px;
    float: right;
    text-align: center;
}

.badge-component {
    width: 51px;
    line-height: 30px;
    vertical-align: top;
    float: right;
    text-align: center;
    margin-top: 7px;
}

.badge-component-score {
    font-size: 18px;
    font-weight: 700;
}

.badge-component-pct {
    width: 11px;
    height: 16px;
    opacity: 0.6;
    color: #000000;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: center;
}

.criteria-score {
    width: 26px;
    height: 20px;
    color: #212121;
    font-family: "Open Sans";
    font-size: 13px;
    font-style: normal;
    letter-spacing: normal;
    line-height: 20px;
    text-align: center;
   
}

.criteria-score-pct {
    width: 11px;
    height: 16px;
    opacity: 0.6;
    color: #000000;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: left;
}


.score-table tr>* {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 2px;
}

.criteria-item-text {
    width: 124px;
    height: 23px;
    color: #5b616a;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    letter-spacing: normal;
    line-height: 23.4px;
    text-align: left;
}

.score-comp-text {
    margin-left: 10px;
    width: 203px;
    height: 22px;
    color: #5b616a;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    letter-spacing: normal;
    line-height: 22px;
    text-align: left;
}

.score-comp-seq {
    width: 7px;
    height: 14px;
    opacity: 0.7;
    color: #c4c4d0;
    font-family: "Open Sans - Bold";
    font-size: 12px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: 19px;
    text-align: left;
}

.row-even {
    background-color: #f8f8f8;
}

.row-odd {
    background-color: #ffffff;
}
.table-row-border td {
    border-bottom-width: 1px;
}

.table-row-no-border td {
    border-bottom: none;
    
}
td.score-center .score-bor {
    text-align: center;
    border-bottom: 1px solid #dee2e6;
}
.sc-pc-align{
    margin-top: -16px;
    margin-left: 15px;
}
.score-center{
    padding-right: 12px;
}
.sc-tot1{
    padding-left: 70px !important;
}
.sc-tot-score{
    margin-right: -87px; 
}

.score-card-icon{
    margin-left: 34px !important;
    margin-bottom: 1px;
}
.score-tooltip-text {
    text-align: left;
}
#scorecard-table-container {
    margin-top:24rem;
}


@media (max-width: 1440px) and (min-width: 1200px){
.sc-tot-score {
    margin-right: -54px;
}
}


@media (max-width: 1440px){
.sc-tot-score {
 margin-right: -82px;
 }
 }

 @media (max-width: 1400px) and (min-width:1366px){
    .sc-tot-score {
     margin-right: -82px;
     }
     }
 @media (max-width: 1415px) and (min-width: 1200px) {
 .sc-tot-score {
 margin-right: -46px;
  }
 } @media (max-width: 1439px) and (min-width: 1416px) {
    .sc-tot-score {
    margin-right: -87px;
     }
    }

 @media (max-width: 1280) {
    .sc-tot-score {
    margin-right: -59px;
     }
     
    }
@media (max-width: 1280) {
 .criteria-score {
    padding-left:16px
         }
        }

@media (max-width:1415px){
    .score-card-icon {
        margin-left: -5px !important;
    }
}