.hd-navs-bar-wd-align {
  position: fixed;
  width: 100%;
  max-width: 1440px;
  background-color: #ffffff;
}

.header-navbar-loginalign {
  padding-left: 20px !important;
  margin-left: 4px;
}

a.nav-link {
  margin-right: 13px;
  color:rgba(0, 0, 0, .55);

}
.nav-link:hover {
  color: rgba(44, 42, 42, 0.55);
}
div#basic-navbar-nav {
  height: 41px;

}
a#basic-nav-dropdown {
  font-weight: 600;
  font-size: 14px;
}
div#basic-navbar-nav.a.nav-link:hover, a.app-login.header-navbar-loginalign.nav-link:hover {
  color: orange;
}
img.d-inline-block.align-top {
 height: 64px;
 width: auto;
    padding-left: 25px;
}
img.d-inline-block.align-bottom {
  height: 36px;
 width: auto;
    padding-left: 25px;
}
.beta{
  padding-top: 2px;
  font-weight: 600;
    letter-spacing: 1px;
    color:#888888;
}
a.app-login.header-navbar-loginalign.nav-link {
  margin-right: 3px;
  margin-inline-start: -7px;
}

a.nav-link {
  font-family: "Open Sans";
  margin: 0 2px;
  letter-spacing: 1.13px;
  text-align: left;
  font-size: 12px;
  margin-inline-end: 4px;
}

nav.navbar.navbar-expand-lg.navbar-light.bg-light.sticky-top {
  height: 44px;
  margin-left: 1px;


}

.ms-auto.justify-content-end.navbar-nav {
  margin-right: 4px;
}

@media (max-width: 1025px) and (min-width: 370px) {
  .navbar-nav{
   display: none; 
  }
}

/* styles.css or your preferred filename */
.nav-link-default {
  color: #79747E;
}

.nav-link-default:hover {
  color: #B53D23;
}

.nav-link-active {
  color: #D85A13 !important; /* Use !important to override Bootstrap's default styles */
}


.goog-te-banner-frame {
  display: none !important;
}
.translate-options li {
  list-style:  none;
}
body {
  top: 0 !important;
}
.skiptranslate {
  display: none !important;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 8rem;
  padding: rem 0;
  margin: -14px;
  font-size: 1rem;
  color: #212529;
  /* text-align: left; */
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  /* border: 1px solid rgba(0, 0, 0, .15); */
  border-radius: .25rem;
}
