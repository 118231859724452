.question-container .question-title {
    width: 709px;
    /* height: 27px; */
    color: #003e7a;
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 1.5px;
    line-height: 30px;
    text-align: left;
    /* margin-top: -52px; */
    text-transform: uppercase;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.question-container .question-main {
    /* margin-left: 127px;
    margin-top: 97px; */
    margin: 3rem 8%;

}

.question-container p,
.question-container label,
.question-container h5 {
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    letter-spacing: normal;
}

.question-container .description-title {
    color: #888888;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    letter-spacing: normal;
    line-height: 22px;
    text-align: left;
    margin-top: 12px;

}

.answer-choices label {
    color: #212121;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: left;
    margin-left: 10px;
    margin-top: 2px;

}

.question-container .checkbox {
    margin-top: -28px;

}

.question-container .checkbox-text {
    width: 220px;
    margin-left: 25px;
    margin-top: 22px;
    border-radius: 8px;
    height: 48px;
    border-radius: 2px;
    border: 1px solid #979797;
    background-color: #f8f8f8;
    padding: 15px;
}
.focus-visible-only :focus-visible {
    border: 1px solid #979797 !important;
   }

.question-container .form-check {
    margin-top: 30px;
    display: flex;
    align-items: center;
}

.question-container .checkbox .radio {
    border: 3px solid #aeb0b5;
}

.question-container .pca-add-btn {
    /* margin-top: 156px; */
    /* margin-right: 116px; */
    width: 136px;
    float: right;
}

.question-container .cate-btn {
    padding-top: 6px !important;
    padding-right: 10px;
    padding-left: 10px;
    float: right;
    background-color: #f0efef;
    border-radius: 70px;
    height: 30px;
    color: #888888;
    margin-top: -2.3rem;
    /* margin-right: 92px; */
    text-align: center;
    padding-top: 4px;
    text-transform: uppercase;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 700;

}

.question-container {
    height: 56.9rem;
}

.form-check-input {
    width: 1.4em !important; 
    height: 1.4em !important;
    border: 3px solid rgba(0,0,0,.25) !important;
}

.back-btn a {
    width: 48px;
    height: 24px;
    color: #aeb0b5;
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 1.3px;
    line-height: 24px;
    text-align: left;
    text-decoration: none;
    margin-left: -9px;
    margin-top: 12px;


}

.skip-btn{
    margin-right: 12px;
    float: right;
    color: #aeb0b5;
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 1.3px;
    line-height: 24px;
    text-align: left;
    text-decoration: none;

}
.pca-question-btn-ro-hi{
    margin-top: 120px;
    /* margin-right: 59px; */
}

.pca-question-bck-skip-btn{
    padding-top: 13px;
}



.pca-question-btn-back {
    width: 48px;
    background-color: white;
    border-color: white;
    color: #aeb0b5;
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 1.3px;
    line-height: 24px;
    text-align: left;
    text-transform: uppercase;
    padding: 0px 0px;
    /* margin-left: 41px; */
    text-decoration: none;

}

.pca-question-skip-continue {
    width: 6rem;
}

.pca-questions-check-alignment{
    display: grid;
    grid-template-columns: 45px auto;
    padding-bottom: 30px;
}