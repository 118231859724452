.funding-eligibility-container-tbl-design {
  width: 847px;
  text-align: initial;
}

.funding-eligibility-spinner-loader-ftbl {
  width: 80px;
  height: 80px;
  margin-left: 21rem;
}

.funding-eligibility-container-tb-head-tenant1 {
  background-color: #003e7a;
  height: 40px;
  color: #ffffff;
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  vertical-align: middle !important;
  text-align: initial;
}
.funding-eligibility-container-tb-head-tenant2 {
  background-color:rgb(55,47,43);
  height: 40px;
  color: #ffffff;
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  vertical-align: middle !important;
  text-align: initial;
}

.funding-eligibility-container-tb-body {
  height: 78px;
  padding-left: 30px;
  vertical-align: middle;
}
.page-container {
  padding-bottom: 30px;
}

.funding-eligibility-container-accordian {
  height: 276px !important;
}

.funding-eligibility-container-btn-learn {
  float: left;
  margin-top: 10px;
  padding-top: 11px;
  }
.funding-eligibility-container-btn-learn:hover {
 color: #000000;
}

.funding-eligibility-container-elibible {
  /* height: 38px; */
  color: #323a44;
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
}

.funding-eligibility-container-disclaimer {
  width: 246px;
  height: 58px;
  color: #323a44;
  font-family: 'Open Sans';
  font-size: 12px !important;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
}

.funding-eligibility-container-amount-r {
  width: 7rem;
}

.funding-eligibility-table > :not(caption) > * > * {
  padding-left: 16px !important;
}

.funding-eligibility-container-btn-style {
  width: 71px;
  height: 25px;
  border-radius: 4px;
  background-color: #aeb0b5;
  line-height: 20px;
  color: #000000;
  font-family: Open Sans;
  font-weight: bold;
  font-size: 12px;
  font-weight: 700;
}

/* .funding-eligibility-container-btn-align:hover {
  color: black;
} */

.funding-eligibility-container-btn-align {
  float: right;
  margin-right: 0;
  margin-top: 7px;
  padding-left: 8px;
  width: 226px;
}

.funding-row {
  padding-bottom: 60px;
}

.funding-eligibility-container-btn-signup {
  float: right;
  margin-right: 0;
  width: 205px;
  height: auto !important;
}

.funding-eligibility-container-btn-cot {
  padding: 0px;
  vertical-align: revert;
  margin-top: 42px;
}

.funding-eligibility-container-tit-tbl-gap {
  margin-top: 26px;
}

/* .funding-eligibility-container-btn-back {
  width: 48px;
  background-color: white;
  border-color: white;
  color: #aeb0b5;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.3px;
  line-height: 24px;
  text-align: left;
  text-transform: uppercase;
  padding: 0px 0px;
  margin-left: 41px;
  text-decoration: none;
} */
.funding-eligibility-container-colspan-drop-body {
  padding-top: 30px !important;
}
.funding-eligibility-container-drp-downaccor {
  margin-right: 30px;
  width: 27px;
  height: 27px;
}

.funding-eligibility-container-btn-back-align-top {
  margin-top: 20px;
  padding-left: 0px !important;
}

.funding-eligibility-container-arrow-spacing {
  margin-bottom: 2px;
  margin-right: 6px;
}

.funding-eligibility-container-btn-eligible-pad {
  padding-top: 2px;
  color: #323a44;
}

.funding-eligibility-container-drop-head {
  color: #323a44;
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
}
.funding-eligibility-purpose {
  color: #323a44;
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
  margin-top: 10px;

}
.funding-eligibility-container-drop-body {
  color: #323a44;
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
}
.funding-eligibility-purpose-body {
  color: #323a44;
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
}

.funding-text{
  color: #888888;
  font-family: "Open Sans";
  font-size: 14px !important;
  font-style: normal;
  letter-spacing: normal;
  line-height: 22px;
  text-align: left;
}

.funding-text a {
text-decoration: none !important;
}

.funding-eligibility-container-drop-body-top {
  padding-left: 4.5rem !important;
}

.funding-eligibility-container-tbl-hd-1 {
  color: #212121;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}

.table > :not(:first-child) {
  border-top: 1px solid currentColor;
}

.funding-eligibility-container-col-program-name {
  width: 225px;
  padding-top: 20px;
  padding-left: 30px;
  vertical-align: middle;
}

.funding-eligibility-container-col-type-of-project {
  width: 135px;
  /* padding-left: 30px !important; */
}

.funding-eligibility-container-col-project-category {
  width: 151px;
}

.funding-eligibility-container-col-program-funding {
  width: 100px;
}

.funding-eligibility-container-col-program-fundtype {
  width: 100px;
}


.funding-eligibility-container-col-eligibility {
  width: 112px;
}

.funding-eligibility-container .main {
  padding: 3rem 6rem;
  height: 52.5rem;
}
.funding-eligibility-container {
  height: fit-content;
  overflow: auto;
}
.funding-eligibility-nodata {
  color: black;
}

.fe-program-drp-down,
.fe-program-drp-up {
  margin-right: 30px;
  width: 27px;
  height: 27px;
  float: right;
}

#fe-programs-table
  > tbody
  > tr.funding-eligibility-container-tb-body.collapsed
  > td
  > svg.fe-program-drp-up {
  display: none;
}
#fe-programs-table
  > tbody
  > tr.funding-eligibility-container-tb-body:not(.collapsed)
  > td
  > svg.fe-program-drp-up {
  display: block;
}

#fe-programs-table
  > tbody
  > tr.funding-eligibility-container-tb-body.collapsed
  > td
  > svg.fe-program-drp-down {
  display: block;
}
#fe-programs-table
  > tbody
  > tr.funding-eligibility-container-tb-body:not(.collapsed)
  > td
  > svg.fe-program-drp-down {
  display: none;
}
.bottom-padding {
  padding-bottom: 60px;
}
.button-padding {
  margin-bottom: 2rem;
}

.links-container {
  white-space: nowrap;
}

.link-item {
  display: inline-block; 
  margin-right: 10px; 
}

.link-item:last-child {
  margin-right: 0; 
}

.eligiblility-links {
  color: #323a44;
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
  margin-top: 10px;

}


.eligiblility-link-body {
  color: #323a44;
  font-size: 12px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;

}
.eligiblility-link-body{
  margin: -19px;
  padding-right: 48px;

}
.eligible-icon-style{
  color: brown;
  size:inherit;
  margin-right:5px;
}
.recipients {
  padding: 20px 0px 0px 30px;
}

.funding-eligibility-fedralShare{

  color: #323a44;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;

}
.funding-eligibility-stateShare{

  color: #323a44;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;

}
.eligibility-program-recipients {
  /* width: 112px; */
  height: 20px;
  color: #323a44;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
  margin-top: 15px;
}
.cost-share{
  display: flex;
}
.cost-share-text{
  padding-right: 15px;

}
.eligibility-text-style{
  font-size: 12px;

}
.eligiblility-additional-link{
  color: #323a44;
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
  margin-left: -22px;
  margin-top: 10px;

}
.eligibility-icon-style{
  color: brown;
  size:inherit;
 
}
.eligibility-body-style{
  
  padding-left: 10px;
    text-align: left;

}
.badge {
  background-color: #babec1; /* Background color of the badge */
  color: black; /* Text color of the badge */
  padding: 0px 10px; /* Adjust padding to control the size of the badge */
  border-radius: 8px; /* Make it round */
  font-size: 12px;
  line-height: 25px;
  width:max-content;
}
.ep-body-ftype {
  /* width: 110px; */
  height: 36px;
  color: #323a44;

  font-size: 12px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
}
.funding-eligibility-print-button{
  margin-left: 189px;
    margin-top: 7px;
}
.funding-filter-icon{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 69%; 
  z-index: 1; 
}
.funding-program-status{
  width: 38%;
  margin-left: 68%;
  margin-bottom: 2%;
}
.funding-program-status{
  padding-left: 30px  !important; 
}
.heding-alignment{
  margin-left: -4px;
}