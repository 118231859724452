.title-head {
    color: #003e7a;
    font-family: "Open Sans";
    font-size: 36px;
    font-style: normal;
    letter-spacing: normal;
    line-height: 44px;
    text-align: left;
}

.title-body {
    /* width: 90%; */
    color: #888888;
    font-family: "Open Sans";
    font-size: 14px !important;
    font-style: normal;
    letter-spacing: normal;
    line-height: 22px;
    text-align: left;
}
.title-description2 {
    /* width: 90%; */
    /* height: 22px;x */
    color: #888888;
    font-family: "Open Sans";
    font-size: 14px !important;
    font-style: normal;
    letter-spacing: normal;
    line-height: 22px;
    text-align: left;
}