.email,
.pass {
  width: 540px;
  height: 48px;
  border-radius: 3px;
  border: 1px solid #979797;
  background-color: #f8f8f8;
}
.log {
  width: 350px;
  height: 45px;
  color: #003e7a;
  font-family: "Open Sans";
  font-size: 36px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 44px;
  text-align: left;
  margin-left: 63px;
}
.subtitle {
  width: 350px;
  /* height: 22px; */
  color: #888888;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 22px;
  text-align: left;
  margin-left: 2%;
  width: 22rem;
  margin-left: 65px;
}
.log-in-modelpop-btn{
  padding-top: 8px;
  font-size: 16px;
  font-weight: 700;
}

.log-in-modelpop-arrow{
  margin-bottom: 3px;
}


/* .log-in-modelpop-btn:hover {
  color: black;
} */

.login-field-row {
  margin-bottom: 30px !important;
}
.login {
  width: 130px;
  height: 48px;
  background-color: #5b9ef4;
  margin-right: 61px;
  position: relative;
  text-align: "end";
}
.signup {
  padding-left: 17px;
  text-decoration: none;
  margin-top: 5px !important;
  width: 101px;
  height: 40px;
  color: #aeb0b5;
  font-weight: bold;
  /* font-size: 16px; */
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.3px;
  line-height: 24px;
  text-align: left;
  text-transform: uppercase;
  margin: 1px;
  background-color: white;
  padding-top: 7px;
}

.btnLogin {
  width: 64px;
  height: 24px;
  margin-left: 4px;
  color: #ffffff;
  letter-spacing: 1.3px;
  text-transform: uppercase;
}
.h6-align{
  color: #dc3545;
  /* float: right; */
  /* margin-left: 340px; */
    margin-top: -14px;
}
.btn-alignment{
  margin-top: 5px;
}
/* .form-control-feedback {
  margin-top: -25px;
  margin-bottom: 60px;
} */

.forgot-password-login {
  font-weight: bold;
  /* font-size: 16px; */
  font-size: 14px;
  text-decoration: none;
  color:#5b9ef4;
  margin-left: -16px;
}

p.forgot-password.text-right {
  margin-left: 30%;
  white-space: nowrap;
  padding: 14px;
  cursor: pointer;
}